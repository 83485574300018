import { format, getTime, formatDistanceToNow } from 'date-fns';
import moment from 'moment';

// ----------------------------------------------------------------------

export function fDate(date: Date | string | number) {
  return format(new Date(date), 'MMMM yyyy');
}

export function fDateTime(date: Date | string | number) {
  return format(new Date(date), 'dd MMM yyyy p');
}

export function fTimestamp(date: Date | string | number) {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date: Date | string | number) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date: Date | string | number) {
  const dateTz = new Date(date).toLocaleString('en-US', { timeZone: 'Asia/Bangkok' });
  return formatDistanceToNow(new Date(dateTz), {
    addSuffix: true,
  });
}

export function fTime(date: Date | string | number) {
  const dateTz = new Date(date).toLocaleString('en-US', { timeZone: 'Asia/Bangkok' });

  return format(new Date(dateTz), 'HH:mm ');
}

export function fTimePassed(d: string) {
  const date = moment(d).fromNow();
  const lowerCaseDate = date.toLowerCase();
  if (lowerCaseDate === '7 days ago') {
    return 'last week';
  } else if (lowerCaseDate === 'a day ago') {
    return 'yesterday';
  } else if (lowerCaseDate === 'a few seconds ago') {
    return 'just now';
  } else if (lowerCaseDate === 'a month ago') {
    return 'last month';
  } else {
    return date
      .replace('seconds', 'sec')
      .replace('minutes', 'mins')
      .replace('hours', 'hr')
      .replace('year', 'yr')
      .replace('months', 'months')
      .replace('days', 'days');
  }
}
