import Box from '@mui/material/Box';
import Card, { CardProps } from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import { useRef, memo, useState } from 'react';
import Slider from 'react-slick';
import CarouselDots from '@components/carousel/CarouselDots';
import Image from '@components/Image';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import { fTime } from '@utils/formatTime';
import { fCurrencyInteger } from '@utils/formatNumber';
import { resize_image } from '@utils/image';
import { OpenTableData } from 'src/types/book-seat';
import TextMaxLine from '@components/TextMaxLine';
import Avatar from '@mui/material/Avatar';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import moment from 'moment';
import LoadingButton from '@mui/lab/LoadingButton';
import AvatarGroup from '@mui/material/AvatarGroup';
import GuestJoinedDetailModal from '@components/course-menu-card/GuestJoinedDetailModal';
import useMediaQuery from '@mui/material/useMediaQuery';

const GuestPendingStatusLabel = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 16,
  left: 16,
  padding: '1px 8px',
  background: theme.palette.grey[400],
  borderRadius: '6px',
  color: theme.palette.grey[800],
  fontWeight: 700,
  fontSize: 12,
  lineHeight: '20px',
}));

const MinGuestStatusLabel = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 16,
  left: 16,
  padding: '1px 8px',
  background: theme.palette.success.main,
  borderRadius: '6px',
  color: '#ffffff',
  fontWeight: 700,
  fontSize: 12,
  lineHeight: '20px',
}));

interface BorderLinearProgressProps {
  value: number;
}

const BorderLinearProgress = styled(LinearProgress)<BorderLinearProgressProps>(
  ({ theme, value }) => ({
    height: 8,
    borderRadius: 4,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: 'rgba(145, 158, 171, 0.24)',
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 0,
      background: `linear-gradient(90deg, #F24E1E 0%, #F56A32 ${Math.pow(
        47.4,
        1 + (97.5 - value) / 97.5
      )}%, #F99948 ${Math.pow(83.85, 1 + (97.5 - value) / 97.5)}%, #FFD966 ${Math.pow(
        97.5,
        1 + (97.5 - value) / 97.5
      )}%)`,
    },
  })
);

const ImageSlide = ({ alt, image, width }: { alt: string; image: string; width: number }) => {
  return (
    <Box sx={{ cursor: 'pointer' }}>
      <Image src={image} alt={alt} sx={{ width: width, height: 240 }} />
    </Box>
  );
};

interface OpenTableCardProps extends CardProps {
  id: string;
  openTable: OpenTableData;
  isBooking: boolean;
  onClick: VoidFunction;
  isButtonLoading?: boolean;
  onButtonClick?: VoidFunction;
}

const OpenTableCard = ({
  id,
  openTable,
  onClick,
  isBooking,
  onButtonClick,
  isButtonLoading,
  ...other
}: OpenTableCardProps) => {
  const theme = useTheme();
  const isUpSm = useMediaQuery(theme.breakpoints.up('sm')); // 600

  const [isOpenGuestJoinedDetail, setIsOpenGuestJoinedDetail] = useState<boolean>(false);

  const width = isUpSm ? 368 : 343;
  const cuisines = openTable?.typeOfCuisines?.map((type) => type.name).join(' • ');

  const ImageSlides = ({ images }: { images: string[] }) => {
    const carouselRef = useRef<Slider | null>(null);
    const settings = {
      speed: 800,
      dots: true,
      arrows: false,
      autoplay: false,
      autoplaySpeed: 5000,
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: false,
      rtl: Boolean(theme.direction === 'rtl'),
      ...CarouselDots({
        rounded: true,
        sx: {
          mt: -4,
          '& li': {
            opacity: 0.5,
          },
          '& li.slick-active': {
            opacity: 0.99,
          },
        },
        color: '#ffffff',
      }),
    };

    return (
      <Card sx={{ borderRadius: 0, height: 240, width: width }}>
        {images ? (
          <Slider ref={carouselRef} {...settings}>
            {images?.map((img, idx) => (
              <div onClick={onClick} style={{ cursor: 'pointer' }} key={idx}>
                <ImageSlide alt={`potioneer-img-${idx}`} image={img} width={width} />
              </div>
            ))}
          </Slider>
        ) : (
          <ImageSlide
            image={resize_image({
              url: '/images/profile-background.png',
              width: width,
            })}
            alt="profile-background"
            width={width}
          />
        )}
      </Card>
    );
  };

  return (
    <>
      <Card
        id={id}
        sx={{
          height: 498,
          width: width,
          borderRadius: 1,
        }}
        {...other}
      >
        <ImageSlides
          images={openTable?.imageSignatureDishes?.map((i) =>
            resize_image({
              url: i.imageUrl,
              height: 240,
              width: width,
            })
          )}
        />

        <CardContent
          sx={{
            height: 258,
            width: width,
            p: 0,
          }}
        >
          <Box
            sx={{
              ':hover': {
                cursor: 'pointer',
              },
              p: 2,
              py: '12px',
            }}
            onClick={onClick}
          >
            {openTable?.status === 'Min. guest' ? (
              <MinGuestStatusLabel>
                <Stack direction="row" alignItems="center" spacing={0.6663}>
                  <Box> {openTable?.status}</Box>
                  <CheckCircleRoundedIcon sx={{ fontSize: 16, color: '#FFFFFF' }} />
                </Stack>
              </MinGuestStatusLabel>
            ) : (
              <GuestPendingStatusLabel>{openTable?.status}</GuestPendingStatusLabel>
            )}
            <TextMaxLine line={1} variant="subtitle2">
              {openTable?.courseName}
            </TextMaxLine>
            <Stack direction="row" spacing={1.02} mt={'12px'}>
              <TextMaxLine line={1} variant="caption" color={theme.palette.grey[500]}>
                {openTable?.chef?.name}
                {' • '}
                {cuisines}
              </TextMaxLine>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1} mt={0.5}>
              <img
                src="https://images.potioneer.com/Icon_MinimalsCC/SVG/ic_location_akGBY_dgE.svg"
                alt="check icon"
                width={16}
                height={16}
              />
              <TextMaxLine line={1} variant="caption" color={theme.palette.grey[500]}>
                {openTable?.courseLocation?.name}
              </TextMaxLine>
            </Stack>
            <Stack direction="row" spacing={1} mt={0.5}>
              <Box
                sx={{
                  mt: '1px',
                }}
              >
                <img
                  src="https://images.potioneer.com/Icon_MinimalsCC/SVG/ic_clock_ID2zfJcMR.svg"
                  alt="check icon"
                  width={16}
                  height={16}
                />
              </Box>
              <Stack direction="row" alignItems="flex-start" spacing={0.5}>
                {isBooking ? (
                  <Typography variant="caption" color={theme.palette.grey[500]}>
                    {fTime(openTable?.bookingDateFrom)}
                  </Typography>
                ) : (
                  <Stack direction="row">
                    {openTable?.timeSlot?.map((time, idx) => (
                      <Typography
                        key={idx}
                        variant="caption"
                        color={theme.palette.grey[500]}
                        ml={idx !== 0 && openTable?.timeSlot?.length - 1 === idx ? 0.5 : 0}
                      >
                        {idx !== 0 && openTable?.timeSlot?.length - 1 === idx
                          ? 'and '
                          : idx === 0
                          ? ''
                          : ', '}
                        {fTime(time)}
                      </Typography>
                    ))}
                  </Stack>
                )}
                <Typography variant="caption" color={theme.palette.grey[500]}>
                  |{' '}
                  {openTable?.bookingDateFrom
                    ? moment(openTable?.bookingDateFrom).format('ddd, DD MMM YYYY')
                    : ''}
                </Typography>
              </Stack>
            </Stack>
          </Box>

          {isBooking ? (
            <>
              <Box
                sx={{ cursor: 'pointer', px: 2 }}
                onClick={() => setIsOpenGuestJoinedDetail(true)}
              >
                <Stack direction="row" alignItems="center" spacing={1}>
                  <AvatarGroup
                    max={4}
                    spacing={6}
                    sx={{
                      '& .MuiAvatar-root': {
                        width: 24,
                        height: 24,
                        fontSize: 12,
                      },

                      '.MuiAvatarGroup-avatar:first-of-type': {
                        fontSize: 12,
                        color:
                          openTable?.guestJoin?.length < 4 ? theme.palette.grey[600] : undefined,
                        backgroundColor:
                          openTable?.guestJoin?.length < 4 ? theme.palette.grey[400] : undefined,
                      },
                    }}
                  >
                    {openTable?.guestJoin.map((guest, idx) =>
                      openTable?.guestJoin?.length === 4 && idx === 3 ? (
                        <Avatar key={idx}>+1</Avatar>
                      ) : (
                        <Avatar
                          key={idx}
                          alt={guest?.name}
                          src={resize_image({
                            url: guest?.imageUrl,
                            width: 24,
                            height: 24,
                          })}
                          sx={{ textTransform: 'uppercase' }}
                        >
                          {guest?.isChef
                            ? guest?.name
                                .split(' ')
                                .slice(0, 2)
                                .map((item) => item && item[0])
                                .join('')
                            : `${guest?.name?.slice(0, 1)}${guest?.lastName?.slice(0, 1) ?? ''}`}
                        </Avatar>
                      )
                    )}
                  </AvatarGroup>

                  <Typography variant="subtitle2">
                    {openTable?.openTableTotalGuest} guests joined
                  </Typography>
                </Stack>
              </Box>

              <Box
                sx={{
                  ':hover': {
                    cursor: 'pointer',
                  },
                  p: 2,
                  py: '16px',
                }}
                onClick={onClick}
              >
                <BorderLinearProgress
                  variant="determinate"
                  value={
                    openTable?.status === 'Min. guest'
                      ? 97.5
                      : (openTable?.openTableTotalGuest / openTable?.minGuest) * 100
                  }
                />
                <Typography
                  fontSize={10}
                  fontWeight={400}
                  textAlign={'right'}
                  color={theme.palette.grey[500]}
                  mt={'3px'}
                >
                  {openTable?.status === 'Min. guest'
                    ? `${openTable?.seatLeft} Seats left`
                    : openTable?.status === 'Guest pending'
                    ? `${openTable?.seatToMeetMinGuest} more to reach min. guest`
                    : `Be the 1st to book`}
                </Typography>
              </Box>
            </>
          ) : (
            <Box
              sx={{
                ':hover': {
                  cursor: 'pointer',
                },
                width: width,
                height: '86px',
              }}
              onClick={onClick}
            />
          )}

          <Stack direction="row">
            <Box
              sx={{
                ':hover': {
                  cursor: 'pointer',
                },
                width: '16px',
                height: '36px',
              }}
              onClick={onClick}
            />

            {isBooking ? (
              <LoadingButton
                id={id}
                loading={isButtonLoading}
                fullWidth
                variant="outlined"
                sx={{
                  // mx: 2,
                  hight: '36px',
                  width: '336px',
                  background: 'primary.main',
                  // position: 'absolute',
                  textTransform: 'none',
                }}
                onClick={onButtonClick}
              >
                Join Now for {fCurrencyInteger(openTable?.pricePerGuest)}/person
              </LoadingButton>
            ) : (
              <LoadingButton
                id={id}
                loading={isButtonLoading}
                fullWidth
                variant="outlined"
                sx={{
                  // mx: 2,
                  hight: '36px',
                  width: '336px',
                  background: 'primary.main',
                  // position: 'absolute',
                  textTransform: 'none',
                }}
                onClick={onClick}
              >
                Open New Table for {fCurrencyInteger(openTable?.pricePerGuest)}/person
              </LoadingButton>
            )}

            <Box
              sx={{
                ':hover': {
                  cursor: 'pointer',
                },
                width: '16px',
                height: '36px',
              }}
              onClick={onClick}
            />
          </Stack>

          <Box
            sx={{
              ':hover': {
                cursor: 'pointer',
              },
              width: width,
              height: '16px',
            }}
            onClick={onClick}
          />
        </CardContent>
      </Card>

      <GuestJoinedDetailModal
        open={isOpenGuestJoinedDetail}
        data={openTable?.guestJoin}
        handleClose={() => setIsOpenGuestJoinedDetail(false)}
      />
    </>
  );
};

export default memo(OpenTableCard);
