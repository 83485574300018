import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import Link from '@mui/material/Link';
import Slide, { SlideProps } from '@mui/material/Slide';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import { formatMobileNumber } from '@utils/formatMobileNumber';
import {
  forwardRef,
  FunctionComponent,
  memo,
  useCallback,
  useMemo,
  useState,
  useEffect,
} from 'react';
import { get_access_token } from '@services/auth';
import { verify_phone, verify_otp, save_phone } from '@services/otp';
import countrtData from '@services/countryCode.json';
import Autocomplete from '@mui/material/Autocomplete';
import OtpInput from './OtpInput';

const Transition = forwardRef(function Transition(props: SlideProps, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  width: 24,
  height: 24,
  color: theme.palette.text.primary,
}));

interface VerifyPhoneModalProps {
  isOpen: boolean;
  onClose: VoidFunction;
  type?: verifyPhoneType;
  callback: () => void;
}

enum VerifyPhoneState {
  MobileInput = 'mobile_input',
  OtpInput = 'otp_input',
}

export enum verifyPhoneType {
  Chef = 'chef',
}

const errorMessageDefault = 'Something went wrong';

const VerifyPhoneModal: FunctionComponent<VerifyPhoneModalProps> = (props) => {
  const { isOpen, onClose, type, callback } = props;
  const [mobileNum, setMobileNum] = useState<string>('');
  const [refCode, setRefCode] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [countryCode, setCountryCode] = useState<string | null>('+66');
  const [currentState, setCurrentState] = useState(VerifyPhoneState.MobileInput);
  const [isRequest, setIsRequest] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);

  useEffect(() => {
    let interval: NodeJS.Timer | undefined = undefined;

    if (isRequest) {
      setCount(60);
      interval = setInterval(() => {
        setCount((prev) => {
          if (prev - 1 <= 0) {
            if (interval) clearInterval(interval);
            setIsRequest(false);
          }

          return prev - 1;
        });

        return () => {
          if (interval) clearInterval(interval);
        };
      }, 1000);
    }
  }, [isRequest]);

  const sendSmsOnClick = useCallback(() => {
    get_access_token().then(async (token) => {
      if (countryCode === '+66') {
        setIsLoading(true);
        const res = await verify_phone(
          token,

          {
            countryCode: countryCode || '+66',
            phone: `${mobileNum.startsWith('0') ? mobileNum.slice(1) : mobileNum}`,
          },
          type
        );
        if (res?.code !== '200' || !res?.data?.[0]?.refCode) {
          setTimeout(() => setIsLoading(false), 300);
          setErrorMessage(res?.message || errorMessageDefault);
          return;
        }
        setIsLoading(false);
        setRefCode(res?.data[0]?.refCode);
        setCurrentState(VerifyPhoneState.OtpInput);
        setIsRequest(true);

        setIsRequest(true);
      } else {
        const res = await save_phone(
          {
            countryCode: countryCode || '+66',
            phone: `${mobileNum.startsWith('0') ? mobileNum.slice(1) : mobileNum}`,
            type: type === 'chef' ? 'chef' : 'user',
          },
          token
        );
        if (res?.code !== '200') {
          setErrorMessage(res?.message || errorMessageDefault);
          return;
        }
        setCurrentState(VerifyPhoneState.MobileInput);
        onClose();
        callback();
      }
    });
  }, [mobileNum, type, countryCode, onClose, callback]);

  const backIconOnClick = useCallback(() => {
    setCurrentState(VerifyPhoneState.MobileInput);
    setMobileNum('');
  }, []);

  const onCloseErrorMessage = useCallback(() => setErrorMessage(''), []);

  const onOtpSubmit = useCallback(
    (otp: string) => {
      get_access_token().then(async (token) => {
        const res = await verify_otp(token, { otp, ref: refCode }, type);
        if (res?.code !== '200') {
          setErrorMessage(res?.message || errorMessageDefault);
          return;
        }
        setCurrentState(VerifyPhoneState.MobileInput);
        onClose();
        callback();
      });
    },
    [refCode, type, callback, onClose]
  );

  const mobilePhoneInput = useMemo(
    () => (
      <Box>
        <Stack spacing={4}>
          <Typography variant="body2">
            {countryCode === '+66'
              ? `Please enter your phone number, we will send you a verification code`
              : `Verify your phone number to ensure that we have the correct contact information to reach you.`}
          </Typography>
          <Autocomplete
            options={countrtData}
            autoHighlight
            getOptionLabel={(option) => option.name}
            renderOption={(props, option) => (
              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                <img
                  loading="lazy"
                  width="20"
                  src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                  alt=""
                />
                {option.name} ({option.dial_code})
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Country/Region"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
              />
            )}
            value={countrtData.find((country) => {
              return country.dial_code === countryCode;
            })}
            // defaultValue={countrtData.find(
            //   (country) => country.dial_code === countryCode
            // )}
            onChange={(e, value) => {
              if (value) {
                // props.form.setValue('countryCode', value.dial_code || '');
                setCountryCode(value.dial_code || '');
              }
            }}
          />
          <TextField
            fullWidth
            label="Mobile number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography variant="body1">{countryCode}</Typography>
                </InputAdornment>
              ),
            }}
            inputProps={{ maxLength: 11 }}
            value={mobileNum || ''}
            onChange={(e) => {
              const isNumber = /^\d+$/.test(e.target.value);
              if (isNumber || e.target.value === '') {
                setMobileNum(e.target.value);
              }
            }}
          />
          <LoadingButton
            loading={isLoading}
            variant="contained"
            color="primary"
            size="large"
            sx={{ textTransform: 'none' }}
            onClick={sendSmsOnClick}
            disabled={countryCode === '+66' ? mobileNum.length < 9 : false}
          >
            {countryCode === '+66' ? 'Next' : 'Save My Phone number'}
          </LoadingButton>
        </Stack>
        <Grid container justifyContent="center" mt={3}>
          <LoadingButton disabled={isLoading} variant="text" size="small" onClick={onClose}>
            Cancel
          </LoadingButton>
        </Grid>
      </Box>
    ),
    [mobileNum, sendSmsOnClick, isLoading, onClose, countryCode]
  );

  const otpInput = useMemo(
    () => (
      <Stack spacing={2}>
        <Typography variant="body2">
          Please enter the verification code
          <br />
          {`sent to +66 ${formatMobileNumber(
            mobileNum.startsWith('0') ? mobileNum.slice(1) : mobileNum
          )}`}
        </Typography>
        <OtpInput onSubmitOtp={onOtpSubmit} sx={{ py: 2.125, px: 1.4375 }} />
        <Grid container justifyContent="center" alignItems="center" spacing={0.5}>
          <Grid item>
            <Typography variant="body2">Didn’t recieve SMS?</Typography>
          </Grid>
          <Grid item>
            <Link
              onClick={() => {
                if (isRequest) return;
                sendSmsOnClick();
              }}
              variant="button"
              sx={{
                color: isRequest ? 'gray' : 'text.primary',
                '&:hover': {
                  textDecoration: isRequest ? 'none' : 'underline',
                  cursor: isRequest ? 'default' : 'pointer',
                },
              }}
            >
              Resend code {!!count && `(${count}s)`}
            </Link>
          </Grid>
        </Grid>
      </Stack>
    ),
    [mobileNum, onOtpSubmit, sendSmsOnClick, count, isRequest]
  );

  const renderCurrentState = useMemo(() => {
    switch (currentState) {
      case VerifyPhoneState.MobileInput:
        return mobilePhoneInput;
      case VerifyPhoneState.OtpInput:
        return otpInput;
      default:
        return mobilePhoneInput;
    }
  }, [currentState, mobilePhoneInput, otpInput]);

  const dialogOnClose = useCallback(() => {
    onClose();
    setMobileNum('');
    setCurrentState(VerifyPhoneState.MobileInput);
  }, [onClose]);

  return (
    <>
      <Dialog
        fullScreen
        fullWidth
        open={isOpen}
        onClose={dialogOnClose}
        aria-labelledby="verify-phone-dialog-title"
        aria-describedby="verify-phone-dialog-description"
        TransitionComponent={Transition}
      >
        <Grid container justifyContent="space-between" alignItems="center" sx={{ p: 2 }}>
          <Grid item>
            {currentState === VerifyPhoneState.OtpInput && (
              <StyledIconButton aria-label="back" onClick={backIconOnClick}>
                <ArrowBackIosNewRoundedIcon />
              </StyledIconButton>
            )}
          </Grid>
          <Grid item>
            <StyledIconButton aria-label="close" onClick={dialogOnClose}>
              <CloseRoundedIcon />
            </StyledIconButton>
          </Grid>
        </Grid>
        <Container maxWidth="xs">
          <Typography variant="h4" mb={2} mt={4}>
            Verify your phone number
          </Typography>
          {renderCurrentState}
        </Container>
      </Dialog>

      <Snackbar
        autoHideDuration={3000}
        open={!!errorMessage}
        onClose={onCloseErrorMessage}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity="error" onClose={onCloseErrorMessage}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default memo(VerifyPhoneModal);
