import * as React from 'react';
import Stack from '@mui/material/Stack';
import Modal from '@components/Modal';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import { resize_image } from '@utils/image';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import { guestJoinData } from 'src/types/book-seat';
import Badge from '@mui/material/Badge';
import TextMaxLine from '@components/TextMaxLine';
import Scrollbar from '@components/Scrollbar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import { useRouter } from 'next/router';
import { url_profile } from '@utils/formatUrl';

type RootStyleProps = {
  isMobile: boolean;
};

const RootStyle = styled(Box)<RootStyleProps>(({ theme, isMobile }) => ({
  '& .slick-list': {
    borderRadius: Number(theme.shape.borderRadius) * 0,
  },
  width: isMobile ? '100%' : 375,
}));

type GuestJoinedDetailModalProps = {
  open: boolean;
  data: guestJoinData[];
  handleClose: () => void;
};

export default function GuestJoinedDetailModal({
  open,
  data,
  handleClose,
}: GuestJoinedDetailModalProps) {
  const router = useRouter();

  const theme = useTheme();
  const isDown426px = useMediaQuery(theme.breakpoints.down(426));

  return (
    <Modal
      // idButtonClose=""
      isOpen={open}
      onClose={handleClose}
      fullScreen={isDown426px}
      maxWidth="xs"
      isBackdropClick
    >
      <Stack direction="column" justifyContent="flex-start" alignItems="center" minHeight="669px">
        <RootStyle isMobile={isDown426px}>
          <Box sx={{ m: 0, mx: 3, mt: '74px' }}>
            <Typography variant="h5">Guest joined</Typography>

            <Scrollbar sx={{ maxHeight: 548, mt: 2 }}>
              <List sx={{ p: 0 }}>
                {data?.map((guest, idx) => (
                  <ListItem
                    sx={{ p: 0 }}
                    key={idx}
                    onClick={() => {
                      if (guest?.isChef) router.push(`${url_profile(guest?.name)}`);
                      else router.push(`${url_profile(guest?.userId)}`);

                      handleClose();
                    }}
                  >
                    <ListItemButton sx={{ p: 0 }}>
                      <Stack direction="row" alignItems="center" width={'100%'} spacing={1} p={2}>
                        <Badge
                          sx={{
                            color: '#073938',
                          }}
                          invisible={!guest?.isChef}
                          overlap="circular"
                          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                          badgeContent={
                            <img
                              alt="chef_hat"
                              src="https://images.potioneer.com/Icon_MinimalsCC/lucide_chef-hat_TXZEGRWsv.svg"
                              style={{ width: '14px' }}
                            />
                          }
                        >
                          <Avatar
                            alt={guest?.name}
                            src={resize_image({
                              url: guest?.imageUrl,
                              width: 48,
                              height: 48,
                            })}
                            sx={{ textTransform: 'uppercase' }}
                          >
                            {guest?.isChef
                              ? guest?.name
                                  .split(' ')
                                  .slice(0, 2)
                                  .map((item) => item && item[0])
                                  .join('')
                              : `${guest?.name?.slice(0, 1)}${guest?.lastName?.slice(0, 1) ?? ''}`}
                          </Avatar>
                        </Badge>

                        <TextMaxLine line={1} fontSize={'12px'} fontWeight={600}>
                          {guest?.name}
                        </TextMaxLine>

                        <Typography variant="body2" fontSize={'12px'} fontWeight={600}>
                          ({guest?.numberOfGuest})
                        </Typography>
                      </Stack>
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Scrollbar>
          </Box>
        </RootStyle>
      </Stack>
    </Modal>
  );
}
