import { get, post, put } from '@utils/fetch';
import { BookingData, YourLocation, BookingDetailData, OpenTableData } from 'src/types/book-seat';

interface PostBookingRequest {
  bookingDate: string;
  courseId: number;
  name: string;
  numberOfGuest: number;
  courseLocationId: number;
  preferredLocation?: string;
}

interface GuestAllergies {
  allergicIds: number[];
  name: string;
  remark: string;
}

// export interface GetGuestAllergicBookingDetail {
//   booking: BookingData;
//   course: {
//     id: number;
//     title: string;
//     chef: {
//       id: number;
//       name: string;
//     };
//   };
// }

export interface PutBookingRequest {
  locationId?: number;
  additionalRequest?: string;
  allergenStatus?: string;
  allergicIds?: number[];
  guestAllergicStatus?: string;
  guestAllergies?: GuestAllergies[];
  isYourLocation?: boolean;
  numberOfGuest?: number;
  occasionId?: number;
  remark?: string;
  yourLocation?: YourLocation;
}

export interface PostGuestAllergyRequest {
  allergenStatus: string;
  allergicIds?: number[];
  name: string;
  remark?: string;
}

export interface PostOpenTableRequest {
  name: string;
  bookingDate?: string;
  bookingNumber?: string;
  courseId?: number;
  courseLocationId?: number;
  numberOfGuest?: number;
  preferredLocation?: string;
}

export async function post_booking(request: PostBookingRequest, token: string) {
  return await post<{ id: number }[]>(`${process.env.NEXT_PUBLIC_API_URL}/booking`, request, {
    Authorization: `Bearer ${token}`,
  });
}

export async function post_ticketing_booking(url: string, token: string) {
  return await post<any>(
    `${process.env.NEXT_PUBLIC_API_URL}/api/v1/ticketing/combine`,
    {
      url: url,
    },
    {
      Authorization: `Bearer ${token}`,
    }
  );
}

export async function get_booking_by_id(bookingId: string, token: string) {
  return await get<BookingData[]>(
    `${process.env.NEXT_PUBLIC_API_URL}/booking/${bookingId}`,
    token.length > 0
      ? {
          Authorization: `Bearer ${token}`,
        }
      : undefined
  );
}

export async function put_booking(request: PutBookingRequest, bookingId: string, token: string) {
  return await put(`${process.env.NEXT_PUBLIC_API_URL}/v2/booking/${bookingId}`, request, {
    Authorization: `Bearer ${token}`,
  });
}

export async function post_booking_submit(bookingId: string, token: string) {
  return await post(`${process.env.NEXT_PUBLIC_API_URL}/booking/${bookingId}/submit`, undefined, {
    Authorization: `Bearer ${token}`,
  });
}

export async function post_booking_guest_allergic(
  request: PostGuestAllergyRequest,
  bookingId: string,
  token: string
) {
  return post(`${process.env.NEXT_PUBLIC_API_URL}/guest-allergic/${bookingId}`, request, {
    Authorization: `Bearer ${token}`,
  });
}

export async function get_guest_allergic_booking_detail(bookingId: string, token: string) {
  return get<BookingData[]>(
    `${process.env.NEXT_PUBLIC_API_URL}/guest-allergic/${bookingId}/booking-detail`,
    {
      Authorization: `Bearer ${token}`,
    }
  );
}

export async function get_booking_check_payment(bookingId: string, token: string) {
  return get<{ isPaid: boolean }[]>(
    `${process.env.NEXT_PUBLIC_API_URL}/booking/${bookingId}/check-payment`,
    {
      Authorization: `Bearer ${token}`,
    }
  );
}

export async function get_chef_waiting_booking_detail(booking_id: number, token: string) {
  return get<BookingDetailData[]>(
    `${process.env.NEXT_PUBLIC_API_URL}/chef/booking-waiting/${booking_id}`,
    {
      Authorization: `Bearer ${token}`,
    }
  );
}

export interface OpenTableResponse {
  total: number;
  data: OpenTableData[];
}

export async function get_open_table(
  page: number,
  limit: number,
  courseId?: number,
  numberOfGuest?: number,
  locationId?: number,
  date?: string
) {
  return get<OpenTableResponse[]>(
    `${process.env.NEXT_PUBLIC_API_URL}/v2/open-table/booking?page=${page}&limit=${limit}${
      courseId ? `&courseID=${courseId}` : ''
    }${numberOfGuest ? `&numberOfGuest=${numberOfGuest}` : ''}${
      locationId ? `&locationID=${locationId}` : ''
    }${date ? `&date=${date}` : ''}`
  );
}

export async function post_open_table(request: PostOpenTableRequest, token: string) {
  return await post<{ id: number }>(
    `${process.env.NEXT_PUBLIC_API_URL}/open-table/booking`,
    request,
    {
      Authorization: `Bearer ${token}`,
    }
  );
}

export async function post_join_open_table(request: PostOpenTableRequest, token: string) {
  return await post<{ id: number }>(`${process.env.NEXT_PUBLIC_API_URL}/open-table/join`, request, {
    Authorization: `Bearer ${token}`,
  });
}
