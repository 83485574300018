import { atom, RecoilState } from 'recoil';
import {
  SelectLocation,
  KitchenCondition,
  KitchenType,
  AllergyInfo,
  GuestAllergyInfo,
  YourLocation,
} from 'src/types/book-seat';
import { Occasion } from 'src/services/occasion';

interface BookSeatState {
  occasion?: Occasion;
  showOccasionError: boolean;
  location: SelectLocation;
  yourLocationInfo?: YourLocation;
  showLocationError: boolean;
  kitchenCondition?: KitchenCondition;
  showKitchenConditionError: boolean;
  additionRequest?: string;
  kitchenType?: KitchenType;
  hasFoodAllergy?: string;
  allergyInfo?: AllergyInfo;
  showHasFoodAllergyError: boolean;
  showAllergyInfoError: boolean;
  hasGuestWithFoodAllergy?: string;
  guestAllergyInfo?: GuestAllergyInfo[];
  showHasGuestWithFoodAllergyError: boolean;
}

export const bookSeatAtomDefaultState: BookSeatState = {
  showOccasionError: false,
  showKitchenConditionError: false,
  showLocationError: false,
  location: SelectLocation.Restaurant,
  showHasFoodAllergyError: false,
  showAllergyInfoError: false,
  showHasGuestWithFoodAllergyError: false,
};

export const bookSeatAtom: RecoilState<BookSeatState> = atom({
  key: 'BOOK_SEAT_STATE',
  default: bookSeatAtomDefaultState,
});
