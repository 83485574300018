import { Occasion } from 'src/services/occasion';
import { Ingredient } from 'src/services/ingredient';
import { Allergy } from 'src/services/upcoming-booking';
import type { Location } from '@typings/course';
import { Chef } from 'src/types/chef';
import { Image } from 'src/services/upload';
import { CuisineType } from 'src/services/cuisine_type';
import { Profile } from '@typings/profile';

export enum StoveType {
  Electric = 'ELECTRIC',
  Induction = 'INDUCTION',
  Gas = 'GAS',
}

export enum SizeOven {
  Toaster = 'TOASTER',
  Baking = 'BAKING',
  Countertop = 'COUNTERTOP',
}

export interface KitchenCondition {
  stoveType?: StoveType;
  stoveNumber?: string;
  ovenAvailable?: boolean;
  sizeOven?: SizeOven;
}

export enum KitchenType {
  Condo = 'CONDO',
  Home = 'HOME',
}

export enum SelectLocation {
  Restaurant = 'restraurant',
  OwnLocation = 'own_location',
}

export interface AllergyInfo {
  foodAllergen?: Ingredient[];
  remark?: string;
}

export interface GuestAllergyInfo extends AllergyInfo {
  guestName?: string;
}

export interface YourLocation {
  id?: number;
  latitude?: string;
  longitude?: string;
  country?: string;
  province?: string;
  district?: string;
  zipCode?: string;
  address?: string;
  name?: string;
  googleCountry?: string;
  googleDistrict?: string;
  googleProvince?: string;
  typeOfKitchen?: string;
  typeOfStove?: string;
  numberOfStove?: number;
  isOven?: boolean;
  sizeOven?: string;
}

export interface GuestInformation {
  name: string;
  phoneNumber: string;
  countryCode: string;
  additionalRequest: any;
  user_id: number;
  numberOfGuest: number;
  foodAllergies: FoodAllergy[];
}

export interface FoodAllergy {
  name: string;
  allergies: Allergys[];
  remark: string;
}

export interface Allergys {
  id: number;
  name: string;
  isActive: boolean;
}

export interface BookingData {
  id: number;
  bookingNumber: string;
  bookingType: string;
  bookingStatus: string;
  bookingVia: string;
  userId: number;
  user: Profile;
  courseId: number;
  course: {
    id: number;
    title: string;
    minimumPrice: number;
    minCapacity: number;
    chefName: string;
    imageSignatureDishes: {
      imageUrl: string;
      thumbnailUrl: string;
    };
    location: Location;
  };
  name: string;
  numberOfGuest: number;
  bookingDateFrom: string;
  bookingDateTo: string;
  occasion: Occasion;
  isYourLocation: boolean;
  courseLocation?: any;
  typeOfKitchen: string;
  typeOfStove: string;
  numberOfStove: number;
  isOven: boolean;
  sizeOven: string;
  additionalRequest: string;
  totalAmount: number;
  netAmount: number;
  depositAmount: number;
  vat: number;
  vatAmount: number;
  serviceCharge: number;
  serviceChargeAmount: number;
  allergies: Allergy[];
  allergenStatus: string;
  guestAllergicStatus: string;
  remark: string;
  acceptedAt: string;
  shortUrl: string;
  additionalServiceFee: number;
  guestInformation: GuestInformation[] | null;
  openTableTotalGuest: number;
  courseName: string;
  displayBookingStatus: string;
  maxGuest: number;
  dinerPhoneNumber: string;
  dinerCountryCode: string;
  minGuest: number;
  yourLocation: YourLocation;
  pricePerGuest: number;
  venueId: number | null;
  venueNetAmount: number;
  venueRentalFee: number;
  venueServiceCharge: number;
  venueServiceChargeAmount: number;
  venueVat: number;
  venueVatAmount: number;
  priceBreakdown: {
    payDeposit: Payment;
    payFull: Payment;
    payRemaining: Payment;
  };
  minimumPrice: number;
  cleaningFee: number;
  netAmountPerGuest: number;
  displayLocationName: number;
  foodAllergies?: Allergy[];
  declineReason: string;
  remainingAmount: number;
  isDepositPaid: boolean;
  paymentOverdueAt: string;
}

export interface Payment {
  pricePerGuest: number;
  numberOfGuests: number;
  courseAmount: number;
  courseServiceCharge: number;
  courseServiceChargeAmount: number;
  courseVat: number;
  courseVatAmount: number;
  courseTotalAmount: number;
  venueBreakdown: VenueBreakdown;
  netAmount: number;
  remainingAmount: number;
  discountAmount: number;
}

export interface VenueBreakdown {
  venueId: number | null;
  venueRentalFee: number;
  rentalFeePerGuest: number;
  cleaningFee: number;
  venueServiceCharge: number;
  venueServiceChargeAmount: number;
  venueVat: number;
  venueVatAmount: number;
  venueNetAmount: number;
}

export interface BookingDetailData {
  id: number;
  bookingNumber: string;
  bookingStatus: string;
  name: string;
  numberOfGuest: number;
  bookingDateFrom: string;
  bookingDateTo: string;
  occasion: Occasion;
  isYourLocation: boolean;
  yourLocation: YourLocation;
  typeOfKitchen: string;
  typeOfStove: string;
  numberOfStove: number;
  isOven: boolean;
  additionalRequest: string;
  depositAmount: number;
  totalAmount: number;
  allergies: Allergy[];
  allergenStatus: string;
  guestAllergicStatus: string;
  remark: string;
  courseId: number;
  courseLocation?: any;
  shortUrl: string;
  vat: number;
  vatAmount: number;
  serviceCharge: number;
  serviceChargeAmount: number;
  additionalServiceFee: number;
  netAmount: number;
  pricePerGuest: number;
  foodAllergies?: Allergy[];
}

export interface guestJoinData {
  imageUrl: string;
  name: string;
  lastName: string;
  numberOfGuest: string;
  isChef: boolean;
  userId: number;
}

export interface OpenTableData {
  id: number;
  chef: Chef;
  courseId: number;
  courseName: string;
  imageSignatureDishes: Image[];
  typeOfCuisines: CuisineType[];
  bookingNumber: string;
  openTableTotalGuest: number;
  courseLocation: Location;
  bookingDateFrom: string;
  bookingDateTo: string;
  pricePerGuest: number;
  maxGuest: number;
  minGuest: number;
  status: string;
  seatLeft: number;
  seatToMeetMinGuest: number;
  type: string;
  timeSlot: string[];
  guestJoin: guestJoinData[];
}
